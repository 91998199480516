import "./App.css";

import { Contact, Features, Footer, Hero } from "./sections";

function App() {
  return (
    <div className="App">
      <Hero />
      <Features />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

// https://codepen.io/MeredithU/pen/OVMjjK?editors=0010
// https://codepen.io/adeveloperdiary/pen/gaqwpZ?editors=0010
// https://codepen.io/omargourari/pen/mOEYPy?editors=1010
