import React from "react";
import { useField } from "formik";

// components
import FormErrorMessage from "./FormErrorMessage";

import styles from "./input.module.css";

function TextArea({ placeholder, ...otherProps }) {
  const [field, meta] = useField(otherProps);
  let className;

  if (meta.touched && meta.error) {
    className = "error";
  } else if (meta.touched && !meta.error) {
    className = "success";
  }

  return (
    <div className={styles.main}>
      <textarea
        className={`${styles.inputText} ${styles[className]}`}
        required
        {...field}
        rows={5}
        placeholder={placeholder}
      ></textarea>
      <FormErrorMessage name={field.name} />
    </div>
  );
}

export default TextArea;
