import React from "react";
// import mobileImage from "../assets/images/desktop-feature (2).png";
import mobileImage from "../assets/images/Desk 2.png";

import checkIcon from "../assets/images/check.png";

import DownloadButton from "../components/DownloadButton";

// style={{
//   background: 'url("./assets/images/feature-bg.png")',
// }}

const featureList = [
  {
    id: 1,
    title: "Saturday Selections",
    body: "Every Saturday, you'll have access to our exclusive race day tips.",
  },
  {
    id: 2,
    title: "Racing calendar",
    body: "Stay up to date on all of the month's forthcoming race events.",
  },
  {
    id: 3,
    title: "Racing articles",
    body: "Access all our horse racing articles any time to stay up to date.",
  },
  {
    id: 4,
    title: "Hassle free signup",
    body: "Minimum requirements for signup.",
  },
];

function Features() {
  return (
    <section className="section feature__container">
      <div className="flex-center" style={{ marginBottom: "7.2rem" }}>
        <h1 className="section__title">Features</h1>
      </div>
      <div className="flex flex-reverse">
        <div className="feature__left-container">
          <div className="hero__phone-container">
            <img src={mobileImage} alt="" className="feature__phone-group" />
          </div>
        </div>
        <div className="feature__right-container">
          <div className="flex flex-column flex-col-start feature-point-list">
            {featureList.map((feature) => {
              return (
                <div className="feature-item" key={feature.id}>
                  <img
                    src={checkIcon}
                    alt="check"
                    className="check-icon"
                    // style={{ marginRight: "1rem", marginBottom: "-1rem" }}
                  />
                  <div>
                    <h4 className="feature-item__title">{feature.title}</h4>
                    <p className="feature-item__subtitle">{feature.body}</p>
                  </div>
                </div>
              );
            })}
            <div className="feature-item feature-button-container desktop-feature-button">
              <img
                src={checkIcon}
                alt="check"
                className="check-icon hide-check-icon"
              />
              <div></div>
              <DownloadButton />
            </div>

            {/* <div className="flex flex-column flex-col-start">
              <h4 className="feature-item__title">
                <img
                  src={checkIcon}
                  alt="check"
                  style={{ marginRight: "1rem", marginBottom: "-1rem" }}
                />
                Racing calendar
              </h4>
              <p className="feature-item__subtitle">
                Stay up to date on all of the month's forthcoming race events.
              </p>
            </div>
            <div className="flex flex-column flex-col-start">
              <h4 className="feature-item__title">
                <img
                  src={checkIcon}
                  alt="check"
                  style={{ marginRight: "1rem", marginBottom: "-1rem" }}
                />
                Racing articles
              </h4>
              <p className="feature-item__subtitle">
                Access all our horse racing articles any time to stay up to
                date.
              </p>
            </div>
            <div className="flex flex-column flex-col-start">
              <h4 className="feature-item__title">
                <img
                  src={checkIcon}
                  alt="check"
                  style={{ marginRight: "1rem", marginBottom: "-1rem" }}
                />
                Hassle free signup
              </h4>
              <p className="feature-item__subtitle">
                Minimum requirements for signup.
              </p>
            </div> */}
          </div>
        </div>
      </div>
      <div className="hero_button-container feature-button-container mobile-feature-button">
        <DownloadButton />
      </div>
    </section>
  );
}

export default Features;
