import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import FormInput from "../FormInput";
import TextArea from "../TextArea";
import FormButton from "../FormButton";

// login validationSchema
const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(5).max(50).label("Name").trim(),
  email: Yup.string().required().min(5).max(255).email().label("Email").trim(),
  message: Yup.string().required().min(2).max(2000).label("Message").trim(),
});

function ContactForm({ onSubmit }) {
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: "",
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <div className="flex flex-column contact-form">
        <div className="flex flex-column contact-field-container">
          <div className="flex contact-field-group">
            <FormInput placeholder="Enter Name" name="name" type="text" />
            <FormInput placeholder="Enter Email" name="email" type="email" />
          </div>
          <TextArea placeholder="Type your message" name="message" />
        </div>
        <FormButton buttonText="Send Message" isDisabled={true} />
      </div>
    </Formik>
  );
}

export default ContactForm;
