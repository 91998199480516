import React from "react";
// import appleIcon from "../assets/images/ic_baseline-apple.png";
import appleIcon from "../assets/images/apple-icon.svg";

import config from "../constants/config";

function DownloadButton() {
  return (
    <a
      className="button flex-center"
      // onClick={handleDownloadClick}
      href={config.appleStoreLink}
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none" }}
    >
      <img src={appleIcon} alt="" className="button-apple-icon" />
      Download the app
    </a>
  );
}

export default DownloadButton;
