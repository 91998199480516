import React from "react";
import heartLogo from "../assets/images/heart.png";
import jhavtechLogo from "../assets/images/jhavtech.png";
import config from "../constants/config";
// import footerJhavtech from "../assets/images/Footer Jhavtech logo.svg";

function Footer() {
  return (
    <footer className="footer__container flex flex-column">
      <p className="footer__text">© Copyright of The Sixth Day Racing. 2023</p>
      <div className="footer-break-line" />
      <a
        className="footer__text-company"
        href={config.jhavtechLink}
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noreferrer"
      >
        Made with <img src={heartLogo} alt="" className="footer-logo" /> by{" "}
        <img src={jhavtechLogo} alt="" className="footer-logo" />
      </a>

      {/* <img src={footerJhavtech} alt="" srcset="" /> */}
    </footer>
  );
}

export default Footer;
