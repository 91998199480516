import axios from "axios";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 409 &&
    error.response.status <= 500;

  if (expectedError) {
    console.log("Error : ", error);
    // alert("An unexpected error occurrred, Internal Server Error");
  }

  return Promise.reject(error);
});

export const baseUrl = process.env.REACT_APP_BASE_URL;
const apiUrl = `${baseUrl}v1/`;

const httpMethodsObject = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  apiUrl,
};

export default httpMethodsObject;
