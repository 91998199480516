import React, { useState } from "react";
import logo from "../assets/images/logo.png";
// import appStore from "../assets/images/app-store.png";
import appStore from "../assets/images/app-store-1.png";

import ContactForm from "../components/form/ContactForm";

import config from "../constants/config";

import { contactUs } from "../services/contactService";

function Contact() {
  const [message, setMessage] = useState("");

  const handleSubmit = async (contactInfo, formikObject) => {
    try {
      const response = await contactUs(contactInfo);
      const data = await response.data;

      setMessage(data.message);

      formikObject.resetForm();

      setInterval(() => {
        setMessage("");
      }, 4000);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 409
      ) {
        console.log("Error :", error);
      }
    }
  };

  return (
    <section className="contact__container">
      <div className="flex">
        <div className="contact__left-container">
          <h3 className="contact__title">CONTACT US</h3>
          <ContactForm onSubmit={handleSubmit} />
          {message && <p className="success">Thank you for contacting us!</p>}
        </div>
        <div
          className="contact__right-container flex flex-column"
          style={{ alignItems: "flex-start" }}
        >
          <img src={logo} alt="" className="contact-logo" />
          <a
            href={config.appleStoreLink}
            className="app-store"
            target="_blank"
            rel="noreferrer"
          >
            <img src={appStore} alt="" className="app-store-logo" />
          </a>
          <a
            href={config.termsLink}
            className="link"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
          <a
            href={config.privacyLink}
            className="link"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </section>
  );
}

export default Contact;
