import React from "react";
import { ErrorMessage } from "formik";

// components
import ErrMessage from "./ErrorMessage";

function FormErrorMessage({ name }) {
  return (
    <ErrorMessage name={name}>
      {(message) => <ErrMessage message={message} />}
    </ErrorMessage>
  );
}

export default FormErrorMessage;
