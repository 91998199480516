import React from "react";
import logo from "../assets/images/logo.png";
// import image1 from "../assets/images/iPhone 13 Pro-1.png";
// import image2 from "../assets/images/iPhone 13 Pro.png";

// import rightLine from "../assets/images/Vector 1 (1).png";
import DownloadButton from "../components/DownloadButton";

// import desktopHero from "../assets/images/desktop-hero.png";
// import desktopHero from "../assets/images/hero.png";
import desktopHero from "../assets/images/Desk 1.png";

// import mobileHero from "../assets/images/mobile-hero.png";

import mobileHero from "../assets/images/Mob 1 (1).png";

function Hero() {
  return (
    <section className="section hero__container ">
      <div className="flex flex-change hero-flex">
        <div className="hero__left-container section__width">
          <div className="hero__logo-container">
            <img src={logo} alt="" className="logo" />
          </div>
          <h1 className="hero__title">ON THE SIXTH DAY WE PUNT</h1>
          <h4 className="hero__subtitle">
            Get Race Day Tips, Calendar & Articles
          </h4>
          <div className="break-line" />
          <p className="hero__note">
            <span>Selections every Saturday all around the country</span>
          </p>
          <div className="hero_button-container">
            <DownloadButton />
          </div>
        </div>
        <div className="hero__right-container">
          {/* <div className="hero__phone-container"> */}
          {/* <img src={rightLine} alt="" className="rightLine" />
              <img src={image1} alt="" className="iphone1" />
              <img src={image2} alt="" className="iphone2" /> */}
          {/* </div> */}
          <img src={desktopHero} alt="" className="hero-desktop-image" />

          <img src={mobileHero} alt="" className="hero-mobile-image" />
        </div>
      </div>
    </section>
  );
}

export default Hero;
