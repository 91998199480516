import React from "react";
import { useFormikContext } from "formik";

import styles from "./button.module.css";

function FormButton({ buttonText, isDisabled = false, ...otherProps }) {
  const { handleSubmit, isValid, dirty } = useFormikContext();

  let isSubmit = true;
  if (isDisabled) {
    isSubmit = dirty && isValid;
  }

  const handleClick = (e) => {
    handleSubmit(e);
  };

  return (
    <button
      className={`${styles.button}`}
      onClick={handleClick}
      {...otherProps}
      disabled={!isSubmit}
    >
      {buttonText}
    </button>
  );
}

export default FormButton;
