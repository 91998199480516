const adminUrl = process.env.REACT_APP_ADMIN_URL;

const config = {
  appleStoreLink:
    "https://apps.apple.com/au/app/the-sixth-day-racing/id1664331430",
  privacyLink: adminUrl + "privacy-policy",
  termsLink: adminUrl + "terms-conditions",
  jhavtechLink: "https://www.jhavtech.com.au/",
};

export default config;
